import React from 'react';
import { CELL_STATE, CELL_VALUE } from './Constants';



export default class Cell extends React.Component {

	/**
	 * Renders a cell.
	 *
	 * @return {string} ReactDOM
	 */
	render () {
		let state = this.props.state;
		let value = this.props.value;

		// Check if cell is opened
		if ( state !== CELL_STATE.OPEN )
			value = null;

		// Check value
		if ( value === 0 )
			value = null;
		else if ( value === CELL_VALUE.MINE )
			value = '💣';
		// Flagged
		else if ( state === CELL_STATE.FLAGGED )
			value = '🚩';

		return (
			<button
				id={this.props.id}
				className={this.props.className}
				onMouseDown={this.props.onMouseDown}
				onMouseUp={this.props.onMouseUp}
				onMouseMove={this.props.onMouseMove}
				>
				{value}
			</button>
		);
	}
}
