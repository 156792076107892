import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Game from './lib/Game.js';


// ========================================

ReactDOM.render(
	<Game />,
	document.getElementById('root')
);









// Disable Right Click
window.addEventListener("contextmenu", e => e.preventDefault());