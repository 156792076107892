// General Settings
export const BOARD_MIN_WIDTH = 5;
export const BOARD_MAX_WIDTH = 20;
export const BOARD_MIN_HEIGHT = 5;
export const BOARD_MAX_HEIGHT = 20;


// Game States
export const GAME_STATE = {
	READY: 1,
	PLAYING: 2,
	WIN: 3,
	LOSE: 4
};



// Cell States
export const CELL_STATE = {
	OPEN: 0,
	CLOSED: 1,
	PUSHED: 2,
	FLAGGED: 3,
	QUESTION: 4
}

export const CELL_VALUE = {
	MINE: 9
}