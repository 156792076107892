import React from 'react';
import Board from './Board.js';
import { GAME_STATE } from './Constants.js';



export default class Game extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			gameState: GAME_STATE.READY,
			height: 10,
			width: 10,
			bombs: 20,
			gameFinish: false
		}
	}

	render() {
		return (
			<div className="game-container">
				<div className="minesweeper">
					<div className="top">
						
					</div>
					<Board height={this.state.height} width={this.state.width} bombs={this.state.bombs} />
					<div className="game-info">
						<div>{/* status */}</div>
						<ol>{/* TODO */}</ol>
					</div>
				</div>
			</div>
		);
	}
}