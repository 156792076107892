import { CELL_STATE } from './Constants.js';

/**
 * Get the IDs of adjacent cells.
 *
 * @param {number}	id				Cell ID.
 * @param {Object}	cells			Cells.
 * @param {array}	cellEdges   	Array of square edges.
 * @param {number}	width			Width of the board.
 *
 * @return {array} Returns an array of adjacent square IDs.
 */
export const GetAdjacentCells = ( id, cells, cellEdges, width ) => {
	let adjacents = [
		{id: Number(id - width - 1), edge: cellEdges.top.concat(cellEdges.left)},
		{id: Number(id - width), edge: cellEdges.top},
		{id: Number(id - width + 1), edge: cellEdges.top.concat(cellEdges.right)},
		{id: Number(id - 1), edge: cellEdges.left},
		{id: Number(id + 1), edge: cellEdges.right},
		{id: Number(id + width - 1), edge: cellEdges.bottom.concat(cellEdges.left)},
		{id: Number(id + width), edge: cellEdges.bottom},
		{id: Number(id + width + 1), edge: cellEdges.bottom.concat(cellEdges.right)},
	];
	let list = [];

	for ( let i = 0; i < adjacents.length; i++ ) {
		// Check if exists
		if ( adjacents[i].id in cells )
			if ( !adjacents[i].edge.includes(id) )
			list.push( adjacents[i].id );
	}

	return list;
}



/**
 * Calculates if the game is won.
 *
 * @param {Object}	cells		Cells.
 * @param {array}	cellsBombs	List of cell IDs with bombs.
 *
 * @return {boolean} Returns true for win, false for lose, null for neither.
 */
export const CalculateWin = ( cells, cellsBombs ) => {
	// Check if a bomb is revealed
	for ( let i = 0; i < cellsBombs.length; i++ )
		if ( cells[cellsBombs[i]].state === CELL_STATE.OPEN )
			return false;

	// Check if some bombs are still hidden
	for ( let i = 0; i < cells.length; i++ )
		if ( !cellsBombs.includes(i) && cells[i].state === CELL_STATE.CLOSED )
			return null;

	return true;
}