import React from 'react';
import Cell from './Cell.js';
import { GetAdjacentCells, CalculateWin } from './Functions.js';
import { GAME_STATE, CELL_STATE, CELL_VALUE } from './Constants.js';



export default class Board extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			cells: [],
			cellsEdges: {top: [], bottom: [], left: [], right: []},
			cellsBombs: [],
			gameState: GAME_STATE.READY,
			tempBombs: [],
			mouseDown: {left: false, right: false, id: 0}
		};

		// Init cells
		for ( let i = 0; i < this.props.width * this.props.height; i++ )
		{
			this.state.cells[i] = {state: CELL_STATE.CLOSED, value: 0};
		}

		// Horizontal Cells
		for ( let i = 0; i < this.props.width; i++ )
		{
			this.state.cellsEdges.left.push( i * this.props.width );

			this.state.cellsEdges.right.push( (i * this.props.width) + this.props.width - 1 );
		}

		// Vertical Squares
		for ( let i = 0; i < this.props.height; i++ )
		{
			this.state.cellsEdges.top.push( i );

			this.state.cellsEdges.bottom.push( this.state.cells.length - this.props.width + i );
		}
	}



	/**
	 * Adds bomb numbers from given square.
	 *
	 * @param {number}	id			Cell ID of bomb.
	 * @param {Object}	cells		Cells.
	 *
	 * @return {object} Modified squares.
	 */
	addBombNumbers( id, cells ) {
		// Get adjacent squares
		let adjacents = GetAdjacentCells( id, cells, this.state.cellsEdges, this.props.width );

		// Add numbers
		for ( let i = 0; i < adjacents.length; i++ )
		{
			if ( cells[adjacents[i]].value !== CELL_VALUE.MINE )
				cells[adjacents[i]].value += 1;
		}
		
		return cells;
	}



	/**
	 * Generates bombs for the board.
	 *
	 * @param {integer}	id			ID of clicked cell.
	 * @param {Object}	cells		Cells.
	 *
	 * @return {Object} Modified cells.
	 */
	generateBombs( id, cells ) {
		let r = id;
		let bombs = [];

		// Generate Bomb
		for ( let b = 0; b < this.props.bombs; b++ )
		{
			// Random number
			while ( r === id || cells[r].value === CELL_VALUE.MINE )
				r = Math.floor(Math.random() * cells.length);

			// Set bomb
			cells[r].value = CELL_VALUE.MINE;

			// Add to bombs array
			bombs[b] = r;

			// Set adjacent numbers
			this.addBombNumbers( r, cells );
		}

		this.setState({
			gameState: GAME_STATE.PLAYING,
			cellsBombs: bombs
		});

		return cells;
	}



	/**
	 * Recursive empty.
	 *
	 * @param {Object}	id			Cell ID.
	 * @param {Object}	cells		Cells.
	 * @param {number}	ignore		Cell ID to ignore.
	 *
	 * @return {Object}
	 */
	clickEmpty( id, cells ) {
		let adjacents = GetAdjacentCells( id, cells, this.state.cellsEdges, this.props.width );

		// Check if clicked square is empty
		if ( cells[id].value === 0 )
		{
			// Check if all squares are revealed
			for ( let i = 0; i < adjacents.length; i++ )
			{
				if ( !this.state.tempBombs.includes(adjacents[i]) )
				{
					cells[adjacents[i]].state = CELL_STATE.OPEN;
					this.state.tempBombs.push( adjacents[i] );
					cells = this.clickEmpty( adjacents[i], cells );		
				}
			}
		}
	
		return cells;
	}



	/**
	 * Reveals nearby cells if flagged.
	 *
	 * @param {Object}	id			Cell ID.
	 * @param {Object}	cells		Cells.
	 *
	 * @return {Object} Modified cells
	 */
	clickNumber( id, cells ) {
		let adjacents = GetAdjacentCells( id, cells, this.state.cellsEdges, this.props.width );
		let flaggedCount = 0;

		// Count Flags
		for ( let i = 0; i < adjacents.length; i++ )
			if ( cells[adjacents[i]].state === CELL_STATE.FLAGGED )
				flaggedCount++;
		
		// Check if plotted
		if ( cells[id].value === flaggedCount )
		{
			for ( let i = 0; i < adjacents.length; i++ )
			{
				// Reveal Tiles
				if ( cells[adjacents[i]].state === CELL_STATE.CLOSED )
				{
					cells[adjacents[i]].state = CELL_STATE.OPEN;
				}
			}
		}

		// Click empties
		for ( let i = 0; i < adjacents.length; i++ )
		{
			// Reveal Tiles
			if ( cells[adjacents[i]].state === CELL_STATE.OPEN && cells[adjacents[i]].value === 0 )
			{
				cells = this.clickEmpty( adjacents[i], cells );
			}
		}

		return cells;
	}



	/**
	 * Sets a super push.
	 *
	 * @param {Object} cells		Cells.
	 * @param {number} [id=mouse]	(Optional) Cell ID of center.
	 * 
	 * @return {Object} Modified cells.
	 */
	setSuperPush( cells, id = this.state.mouseDown.id ) {
		let adjacents = GetAdjacentCells( id, cells, this.state.cellsEdges, this.props.width );

		// Set center
		if ( cells[id].state === CELL_STATE.CLOSED )
			cells[id].state = CELL_STATE.PUSHED;
		
		// Set adjacents
		for ( let i = 0; i < adjacents.length; i++ )
		{
			if ( cells[adjacents[i]].state === CELL_STATE.CLOSED )
				cells[adjacents[i]].state = CELL_STATE.PUSHED;
		}

		return cells;
	}



	/**
	 * Unsets a super push.
	 *
	 * @param {Object} cells		Cells.
	 * @param {number} [id=mouse]	(Optional) Cell ID of center.
	 * 
	 *  @return {Object} Modified cells.
	 */
	unsetSuperPush( cells, id = this.state.mouseDown.id ) {
		let adjacent = GetAdjacentCells( id, cells, this.state.cellsEdges, this.props.width );
		
		// Unset squares
		for ( let i = 0; i < adjacent.length; i++ )
		{
			if ( cells[adjacent[i]].state === CELL_STATE.PUSHED )
				cells[adjacent[i]].state = CELL_STATE.CLOSED;
		}

		return cells;
	}



	/**
	 * Handles mouse down events.
	 *
	 * @param {Object}	e		Click event.
	 * @param {integer}	id		ID of cell.
	 */
	onMouseDown( e, id ) {
		let cells = this.state.cells.slice();
		let mouseDown = this.state.mouseDown;

		// Check if game is already won or lost
		if ( this.state.gameState === GAME_STATE.WIN || this.state.gameState === GAME_STATE.LOSE )
			return;

		// Left Click
		if ( e.button === 0 )
		{
			mouseDown.left = true;
			mouseDown.id = id;

			if ( cells[id].state === CELL_STATE.CLOSED )
				cells[id].state = CELL_STATE.PUSHED;

			// Check double click
			if ( mouseDown.right )
				cells = this.setSuperPush( cells );
		}

		// Right Click
		if ( e.button === 2 )
		{
			mouseDown.right = true;
			
			if ( mouseDown.left ) {
				cells = this.setSuperPush( cells );

				// Reveal nearby
				if ( cells[id].value > 0 && cells[id].value < CELL_VALUE.MINE )
					cells = this.clickNumber( id, cells );
			} else {
				// Check if cell is closed
				switch ( cells[id].state ) {
					case CELL_STATE.CLOSED:
						cells[id].state = CELL_STATE.FLAGGED;
						break;

					case CELL_STATE.FLAGGED:
						cells[id].state = CELL_STATE.CLOSED;
						break;
				}
			}
		}
	
		this.setState({
			cells: cells,
			mouseDown: mouseDown
		});
	}



	/**
	 * Handles mouse up events.
	 *
	 * @param {Object}	e		Click event.
	 * @param {integer}	id		ID of cell.
	 */
	onMouseUp( e, id ) {
		let cells = this.state.cells.slice();
		let mouseDown = this.state.mouseDown;

		// Check if game is already won or lost
		if ( this.state.gameState === GAME_STATE.WIN || this.state.gameState === GAME_STATE.LOSE )
			return;

		// Left Click
		if ( e.button === 0 )
		{
			// Check if pressed within game
			if ( !mouseDown.left )
				return;

			// Set flag
			mouseDown.left = false;

			// Check if bombs are set
			if ( this.state.gameState === GAME_STATE.READY )
				cells = this.generateBombs( id, cells );

			// Unset squares
			cells = this.unsetSuperPush( cells );

			// Check double click
			if ( mouseDown.right ) {
				if ( cells[id].state === CELL_STATE.OPEN && cells[id].value > 0 && cells[id].value < CELL_VALUE.MINE ) {
					// Reveal nearby
					if ( cells[id].value > 0 && cells[id].value !== CELL_VALUE.MINE ) {
						cells = this.clickNumber( id, cells );
					}
				}
			} else {
				// Reveal Tile
				cells[id].state = CELL_STATE.OPEN;

				// Reveal adjacent
				if ( cells[id].value === 0 )
				{
					this.setState({ tempBombs: [] });
					cells = this.clickEmpty( id, cells );
				}
			}

			
		}

		// Right Click
		if ( e.button === 2 )
		{
			// Check if pressed within game
			if ( !mouseDown.right )
				return;

			// Check double click
			if ( mouseDown.left )
				cells = this.unsetSuperPush( cells );

			// Set flag
			mouseDown.right = false;
		}

		// Set squares and trigger update
		this.setState({
			cells: cells,
			mouseDown: mouseDown
		});
	}



	/**
	 * Handles mouse move event.
	 *
	 * @param {integer}	i		ID of moved cell.
	 */
	onMouseMove( e ) {
		let cells = this.state.cells.slice();
		let mouseDown = this.state.mouseDown;
		let target = Number(e.target.id);

		// Check if mouse is released from outside
		if ( e.buttons === 0 )
		{
			// Unset square variables
			if ( cells[mouseDown.id].state === CELL_STATE.PUSHED )
				cells[mouseDown.id].state = CELL_STATE.CLOSED;
			cells = this.unsetSuperPush( cells );

			// Unset state variables
			mouseDown.left = false;
			mouseDown.right = false;
			mouseDown.id = 0;		
		}

		// Check if pushing a new square
		if ( mouseDown.left && mouseDown.id != target )
		{
			// Check if double clicking
			if ( mouseDown.right )
			{
				// Check if super pushed
				cells = this.unsetSuperPush( cells );

				// Set new pushed down square
				cells = this.setSuperPush( cells, target );
			}
			else
			{
				if ( cells[mouseDown.id].state === CELL_STATE.PUSHED )
					cells[mouseDown.id].state = CELL_STATE.CLOSED;
			}
				
			// Unset square variables
			if ( cells[target].state === CELL_STATE.CLOSED )
				cells[target].state = CELL_STATE.PUSHED;
			mouseDown.id = target;			
		}

		// Set squares and trigger update
		this.setState({
			cells: cells,
			mouseDown: mouseDown
		});
	}



	/**
	 * Resets a game.
	 *
	 * @param {integer}	id			ID of cell to render.
	 */
	resetGame() {
		let cells = [];
		
		// Reset cells
		for ( let i = 0; i < this.props.width * this.props.height; i++ )
		{
			cells[i] = {state: CELL_STATE.CLOSED, value: 0};
		}

		// Resets game state
		this.setState({
			gameState: GAME_STATE.READY,
			cells: cells
		});
	}


	/**
	 * Generates the game status text.
	 * 
	 * @return {string} Game status text.
	 */
	getGameStatusText() {
		let status;

		// State
		switch ( this.state.gameState ) {
			case GAME_STATE.READY:
				status = "😊";
				break;

			case GAME_STATE.PLAYING:
				status = "😀";
				break;

			case GAME_STATE.WIN:
				status = "😎";
				break;

			case GAME_STATE.LOSE:
				status = "😭";
				break;
		}	

		return status;
	}



	/**
	 * Generates the CSS class names.
	 * 
	 * @param {number}	id			ID of Cell.
	 *
	 * @return {string} CSS class names.
	 */
	getClassName( id ) {
		let className = 'cell';

		// State
		switch ( this.state.cells[id].state ) {
			case CELL_STATE.OPEN:
				className += ' open';
				// Value
				if ( this.state.cells[id].value > 0 )
					if ( this.state.cells[id].value === CELL_VALUE.MINE )
						className += ' bomb';
					else
						className += ' near-' + this.state.cells[id].value;
				break;

			case CELL_STATE.PUSHED:
				className += ' pushed';
				break;

			case CELL_STATE.FLAGGED:
				className += ' flagged';
				break;

			case CELL_STATE.QUESTION:
				className += ' question';
				break;
		}

		

		return className;
	}



	/**
	 * Renders a cell.
	 *
	 * @param {integer}	id			ID of cell to render.
	 */
	renderCell( id ) {
		return (
			<Cell
				key={id}
				id={id}
				state={this.state.cells[id].state}
				value={this.state.cells[id].value}
				onMouseDown={(e) => this.onMouseDown(e, id)}
				onMouseUp={(e) => this.onMouseUp(e, id)}
				onMouseMove={(e) => this.onMouseMove(e)}
				className={this.getClassName( id )}
			/>
		);
	}



	
	render() {
		let gameStatus;
		let status;
		let rows = [];
		let i = 0;
		
		// Check if bombs are set
		if ( this.state.gameState === GAME_STATE.PLAYING )
		{
			// this.props.gameState = GAME_STATE.PLAYING;
			gameStatus = CalculateWin( this.state.cells, this.state.cellsBombs );

			// Check if win
			if ( gameStatus === true )
			{
				this.state.gameState = GAME_STATE.WIN;
			}

			// Check if lose
			if ( gameStatus === false )
			{
				this.state.gameState = GAME_STATE.LOSE;
			}
		}

		// Renders board
		for ( let h = 0; h < this.props.height; h++ )
		{
			for ( let w = 0; w < this.props.width; w++ )
			{
				rows.push(this.renderCell(i));
				i++;
			}
		}

		return (
			<div className="game-board">
				<div className="status">
					<div className="face" onMouseUp={() => this.resetGame()}>{ this.getGameStatusText() }</div>
				</div>
				{rows}
			</div>
		);
	}
}